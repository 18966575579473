<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo" :to="{ path: '/' }">
      <!-- <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2> -->
      <img src="../../assets/images/logo/logo-nft-dark-nav.png" alt="" width="233px" />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("errorPage.Page_Not_Found") }} 🕵🏻‍♀️</h2>
        <p class="mb-2">
          {{ $t("errorPage.Oops") }}
        </p>

        <b-button variant="primary" class="mb-2 btn-sm-block" :to="{ path: '/' }">
          {{ $t("errorPage.Back_to_home") }}
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  methods: {
    checkDir() {
      // console.log(localStorage.getItem("language"));

      if (localStorage.getItem("language") == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        document.body.style.fontFamily = "Tajawal";
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        // document.body.style.fontFamily = "Montserrat,Helvetica,Arial, serif";
        document.body.style.fontFamily = "nftbplus,sans-serif";
      }
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  mounted() {
    this.checkDir();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
